
.container {
  /* background-image: url(./images/image.jpg);
  background-size: cover; */

}
.houses {
  display: flex;
  flex-wrap: wrap;

} 

.house {
  border: 1px solid black;
  text-align: center;
  width: 500px;
  padding: 10px;
  margin: 20px;
  font-family: 'Libre Baskerville', serif;
}
